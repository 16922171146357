import { Scrollbars } from 'react-custom-scrollbars-2';
import './privacyPolicy.css';

function privacyPolicy() {
    return (
        <Scrollbars className="ScrollBar"  renderScrollbarHorizontal={props => <div {...props}/>}>
            <div className="privacyPolicy">
                <h1>How Xenon Interactive uses your data and how they are treated in Elysium Online:</h1>
                <p>Xenon Interactive built, and is building Elysium Online as a free application. This Game is provided by Xenon Interactive at no cost and is intended for use as is.
                    This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use this Game.
                    If you choose to use the application, you agree by default to the collection and use of your information as explained in this policy.</p>
                <h2>Information Collection and Use:</h2>
                <p>This Service uses and save every account created in the application and related.
                    All the users password are encrypted and inaccessible for everyone outside of the application and the support itself and these are only used in game [referred later as IG] to permit connection to the server.
                    Other IG data such as characters information and position are also collected and saved. Your device Internet Protocol (IP) may also collected and saved, otherwise defined in this Privacy Policy.
                    The game, it&#8217;s team, and it&#8217;s support always try granting the maximum security possible for every users data, and will never use or sell data from the users.</p>
                <h2>Log Data:</h2>
                <p>Whenever you use this Service, in a case of an error server or client side, data and information about the used device called Log Data, are collected.
                    This Log Data may include information such as your device IP address, device name, operating system,
                    the configuration of the app (including version, window state, settings, etc.) when using the game, the time and date of your use of the Service, and other statistics.</p>
                <h2>Security:</h2>
                <p>We value your trust in providing us your personal Information (password, email, account name), thus we are striving to use commercially acceptable means of protecting it.
                    However, remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                <h2>Changes to privacy policy:</h2>
                <p>This Privacy Policy may update from time to time. Thus, you are advised to review this page periodically for any changes.
                    You will be notified of any changes posted on this page, by following the different social media related to the game. These changes are effective immediately after they are posted on this page.</p>
            </div>
        </Scrollbars >
    );
}

export default privacyPolicy;