import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './index.css';
import './fonts/Pixellari.ttf';

import App from './App';
import Home from './Home';
import PrivacyPolicy from './privacyPolicy';
import DeleteMe from './DeleteMe';
import Navbar from "./components/Navbar";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path='/' element={<div className="background"></div>} />
        <Route exact path='/home' element={<div className="background"></div>} />
        <Route exact path='/update-plan' element={<div className="background2"></div>} />
        <Route exact path='/privacy-policy' element={<div className="background2"></div>} />
        <Route exact path='/delete-me' element={<div className="background"></div>} />
      </Routes>
      <div className="centered">
        <Navbar />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/home' element={<Home />} />
          <Route exact path='/update-plan' element={<App />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='/login' element={<App />} />
          <Route exact path='/delete-me' element={<DeleteMe />} />
        </Routes>
      </div>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
