import { useState } from "react";
import { Buffer } from 'buffer';
import './DeleteMe.css';

function DeleteMe() {
  const [formData, setFormData] = useState({ account: "", password: "" });
  const [isChecked, setIsChecked] = useState(false);
  const [color, setColor] = useState('infoLabel');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleCheckChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const DeleteUser = async () => {
    let headers = new Headers();
    headers.set('Authorization', 'Basic ' + Buffer.from(formData.account + ":" + formData.password).toString('base64'));
    let response = await fetch('https://api.elysium-online.com/user', { headers: headers, method: 'DELETE' }).catch(error => {
      return { status: 404 };
    });
    if (response.status == null || response.status == 404) {
      return { status: false, msg: "API is not responding!" };
    }
    if (response.status == 200) {
      return { status: true, msg: "" };
    }
    let json = await response.json();
    return { status: false, msg: json.message };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var x = document.getElementById("infoLabel");
    if (!isChecked) {
      x.style.display = "block";
      x.innerHTML = "You have not checked the agreement checkbox.";
      setColor("infoLabelError");
      return;
    }
    //Request DELETE
    const res = await DeleteUser();
    if (res.status) {
      x.style.display = "block";
      x.innerHTML = "User deleted successfully.";
      setColor("infoLabelSuccess");
    } else {
      x.style.display = "block";
      x.innerHTML = "User deletion failed: " + res.msg;
      setColor("infoLabelError");
    }
  };

  return (
    <div className="DeleteMe">
      <h1>Request to Remove Personal Information</h1>
      <p>WARNING: PROCEEDING WITH THIS REQUEST WILL PERMANENTLY REMOVE ALL INFORMATION FROM YOUR ELYSIUM ONLINE ACCOUNT.</p>
      <form onSubmit={handleSubmit}>
        <div className="form_line">
          <label className="input-label" htmlFor="account">Account Name:</label>
          <input type="text" id="account" name="account" placeholder="Enter account name..." value={formData.account} onChange={handleChange} />
        </div>
        <div className="form_line">
          <label className="input-label" htmlFor="password">Password:</label>
          <input type="password" id="password" name="password" placeholder="Enter password..." value={formData.password} onChange={handleChange} />
        </div>
        <label className="agreement-label" htmlFor="agreement">
          <input type="checkbox" id="agreement" name="agreement" checked={isChecked} onChange={handleCheckChange} />
          I understand that I am requesting to have all of my data removed. I UNDERSTAND THAT THAT DATA WILL NOT BE RECOVERABLE.
        </label>
        <button type="submit">Submit</button>
      </form>
      <label className={color} id="infoLabel"></label>
    </div>
  );
}

export default DeleteMe;
