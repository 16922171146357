import { useEffect, useState } from "react";
import { getTopNav } from "../data/navbarData";
import logo from '../img/logo.png'

const Navbar = () => {
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    setNavItems(getTopNav());
  }, []);

  return (
    <nav className="nav" style={{ backgroundColor: 'rgba(61,65,72,0.5)' }}>
      <ul className="nav_menu">
        <img src={logo} className="nav_logo" alt="logo" />
        {navItems.map((item) => (
          <li key={item.id} className="nav_item">
            <a href={item.href} className="nav_link">
              {item.label}
            </a>
          </li>
        ))}
        <div className="space"></div>
        {/* <li key={"Account"} className="nav_item">
          <a href={"#"} className="nav_link">
            {"Account"}
          </a>
        </li> */}
      </ul>
    </nav>
  );
};

export default Navbar;