import title from './img/title.png';
import itch from './img/itch.svg';
import './Home.css';

function Home() {
  return (
    <div className="Home">
      <img src={title} className="Home-logo" alt="title" />
      <h1>Download Elysium Online!</h1>
      <p>Available for PC & Android, for free!</p>
      <div className="Buttons">
      <a href='https://xenon-interactive.itch.io/elysium-online'><img class="buttonImg-Itch" alt='Available on itch.io' src={itch}/></a>
      <a href='https://play.google.com/store/apps/details?id=com.pabloxenon.elysiumonline'><img class="buttonImg-GP" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
      </div>
    </div>
  );
}

export default Home;
